import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box } from "../../components/Core";
import Seo from "../../components/Seo";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import imgMetaCardSquare from "../../assets/images/studies/breedon_card_square.jpg";
import imgMetaCardTwitter from "../../assets/images/studies/breedon_card_twitter.jpg";

const MetaCard = ({
	color = "primary",
	className,
	iconName,
	title,
	children,
	...rest
}) => (
	<Box
		bg="light"
		border="1px solid"
		borderColor="border"
		px="20px"
		pt="18px"
		pb="10px"
		borderRadius={10}
		className={`d-flex ${className}`}
		{...rest}
		css={`
		min-width: 100%;
		width: 100%;
	  `}
	>
		<div>
			<Title variant="card" mb={0}>
				{title}
			</Title>

			<Text color="primary">{children}</Text>
		</div>
	</Box>
);

const HeaderImg = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
  max-height: 480px;
`;

const BreedonCaseStudy = (props) => {

	const data = useStaticQuery(graphql`
		query {
			breedon_logo: file(relativePath: { eq: "studies/breedon_logo.png" }) {
				childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed
          }
				}
			}

			breedon_header: file(relativePath: { eq: "studies/breedon_header.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}

		}
	`)

	return (
		<>
			<PageWrapper footerDark>
				<Seo
					title="Opqo | Breedon Case Study | Mobile for Maximo"
					description="Breedon went from being introduced to Opqo to running a proof of concept in less than 1 month, including IT reviews and testing."
					image={imgMetaCardSquare}
					twitterImage={imgMetaCardTwitter}
					pathname={props.location.pathname}
				/>
				<Section className="pb-5">
					<Container>
						<Row className="justify-content-center text-center">
							<Col>
								<Box className="text-center">
									<Img fixed={data.breedon_logo.childImageSharp.fixed} />
								</Box>
								<Box pt={[4, null, null, 5]} pb={5}>
									<HeaderImg>
										<Img fluid={data.breedon_header.childImageSharp.fluid} />
									</HeaderImg>
								</Box>
							</Col>
						</Row>
						<Row className="justify-content-center">
							<Col lg="3" className="order-lg-1">
								<MetaCard title="Industry">
									Manufacturing
								</MetaCard>
								<MetaCard title="Sub-Industry" mt={3}>
									Construction Materials
								</MetaCard>
								<MetaCard title="Date" mt={3}>
									January 2022
								</MetaCard>
							</Col>
							<Col lg="7" className="pt-5 pt-lg-0 order-lg-0">
								<Text>
									Breedon is an industry leading construction materials company with more than 350 locations
									in Great Britain and Ireland.
								</Text>
								<Text mt={3}>
									Breedon produces cement, aggregates, asphalt, ready-mixed concrete,
									slate, specialist concrete, clay products, as well as offering a range of contracting services.
								</Text>
								<Text mt={3}>
									Breedon is committed to fossil fuel replacement and strives for continued carbon reduction.
								</Text>
							</Col>
						</Row>
					</Container>
				</Section>
				<Section className="position-relative" bg="greyBackground">
					<Container>
						<Row>
							<Col lg="10" className="offset-lg-1">
								<Title>
									Customer Issue
								</Title>
								<Text>
									Breedon needed to put Maximo work orders and inventory capabilities in the
									hands of their field technicians. They had deployed another mobile product,
									but it had proven unreliable when disconnected.
								</Text>
								<Text mt={3}>
									Breedon had resorted to paper work orders, requiring technicians to travel
									back to the office to type in notes, issue requests for parts
									and create follow up work orders in Maximo desktop.
								</Text>
							</Col>
						</Row>
					</Container>
				</Section>
				<Section className="position-relative">
					<Container>
						<Row>
							<Col lg="10" className="offset-lg-1">
								<Title>
									Sharptree Solution
								</Title>
								<Text>
									Breedon adopted Opqo, Sharptree’s mobile solution for Maximo.
								</Text>
								<Text mt={3}>
									Breedon went from being introduced to the product to running a proof of concept
									in less than 1 month, including IT reviews and testing.
								</Text>
								<Text mt={3}>
									Sharptree’s resale partner Vetasi was instrumental in implementation workshops,
									soliciting feedback from the technicians. Sharptree rapidly incorporated the
									feedback into the core Opqo product, which was instrumental in adoption and effective change management.
								</Text>
							</Col>
						</Row>
					</Container>
				</Section>
				<Section className="position-relative" bg="primaryPurple">
					<Container>
						<Row>
							<Col lg="10" className="offset-lg-1">
								<Title color="light">
									Impact
								</Title>
								<Text mb={3} color="lightShade">
									Breedon has seen increased efficiency, improved access to data and productivity gains.
									These gains were achieved rapidly as the product was in production within 2 months of being introduced to Opqo.
								</Text>
								<Text mt={3} color="lightShade">
									Breedon can now plan and schedule work more effectively and react to problems in near real time.
								</Text>
								<Text mt={3} color="lightShade">
									Breedon has identified a number of areas where they can now make more efficient use of their investment in Maximo
									to drive real time improvements and exceptional data quality.
								</Text>
							</Col>
						</Row>
					</Container>
				</Section>
			</PageWrapper>
		</>
	);
};

export default BreedonCaseStudy;
